export function formatDateOld(todayStr){
    let today = new Date(todayStr);
    let dd = today.getDate();
    let mm = today.getMonth()+1; // January is 0!
    const yyyy = today.getFullYear();
    if(dd<10){dd=`0${dd}`} if(mm<10){mm=`0${mm}`} today = `${dd}-${mm}-${yyyy}`;
    
    return `${dd}-${mm}-${yyyy}`;
}

export function formatDate(date, month, year){
    let today = new Date(year, month, date);
    let dd = today.getDate();
    let mm = today.getMonth()+1; // January is 0!
    const yyyy = today.getFullYear();
    if(dd<10){dd=`0${dd}`} if(mm<10){mm=`0${mm}`} today = `${dd}-${mm}-${yyyy}`;
    
    return `${dd}-${mm}-${yyyy}`;
}


// todaystr = Fri Apr 08 2022 12:18:56 GMT+0530 (India Standard Time) Convert string dd-mm-yyyy
export function formatDateFromPicker(todayStr){
    const today = new Date(todayStr);
    const date = today.getDate();
    const month = today.getMonth(); // January is 0!
    const year = today.getFullYear();
    
    return formatDate(Number(date), Number(month), Number(year));
}

// format todayStr = 2022-04-06 00:00:00 Convert string dd-mm-yyyy
export function formatDateFromDb(todayStr){
    const year = todayStr.toString().substring(0,4);
    const month = todayStr.toString().substring(5,7);
    const date = todayStr.toString().substring(8,10);

    return formatDate(Number(date), Number(month)-1, Number(year));
}

export function formatDateStrToYMD(todayStr){
    console.log(todayStr)
    const year = todayStr.substring(0,4);
    const month = todayStr.substring(5,7);
    const date = todayStr.substring(8,10);
    console.log({year,month,date})

    return {'dd':date, 'mm':month, 'yyyy':year };
}

// Convert string dd/mm/yyyy
export function formatDateSlash(todayStr){
    let today = new Date(todayStr);
    let dd = today.getDate();
    let mm = today.getMonth()+1; // January is 0!

    const yyyy = today.getFullYear();
    if(dd<10){dd=`0${dd}`} if(mm<10){mm=`0${mm}`} today = `${dd}/${mm}/${yyyy}`;
    
    return today;
}

// Convert string dd-mm-yyyy (strDate) ---> yyyy-mm-dd
export function formatStringToDate(strDate){
    const newdate = strDate.split("-").reverse().join("-");
    // Convert string - yyyy-mm-dd ---> date - Object Mon Feb 07 2022 10:55:12 GMT+0530 (India Standard Time)
    const resultDate = new Date(newdate);
    return resultDate;
}

// Convert string dd-mm-yyyy (strDate) ---> yyyy-mm-dd
export function formatStrDate(strDate){
    const newdate = strDate.split("-").reverse().join("-");
    // Convert string - yyyy-mm-dd ---> date - Object Mon Feb 07 2022 10:55:12 GMT+0530 (India Standard Time)
    // const resultDate = new Date(newdate);
    return newdate;
}


export function findMonthDiff(startDate, endDate){
    const dateDiff = endDate - startDate;
    const yearOffset = new Date(startDate - startDate).getFullYear();
    const date = new Date(dateDiff);
    const month = (date.getFullYear() - yearOffset) * 12 + date.getMonth()
    return month;
}


export function strToDate(dd, mm,   yyyy)
{
    const newDate = `${yyyy}-${mm}-${dd}`;
    return newDate;
}

export function todayDate()
{
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return {'dd':dd, 'mm':mm, 'yyyy':yyyy};
}

export const dateFormatForDataGridColumn = (cell) => {
    const temp = cell.split('-');
    const tempJoin = `${temp[2]}-${temp[1]}-${temp[0]}`;
    return (
        <span>
           {tempJoin}
        </span>
    );
  };