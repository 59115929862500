// import { useLocation } from 'react-router-dom';
import ServerURL from './ServerURL';


// this for api connect with server 

export async function getHostConfig(){

    const local = "http://localhost";
    const livePath = 'https://meenakshi.dynu.net/backend/index.php';
    // const livePath = 'https://crm.vellimaaligai.com/index.php';
 
    // important to change in local development 
    // const resourcesPath = process.env.NODE_ENV === "production" ? window.location.origin : local;
    
    const resourcesPath = livePath;

    return resourcesPath;
}

// export function getHost(){
//     const localhost = "localhost:8000"
//     // const ngrok = "https://c754-2405-201-e027-606b-e973-31f0-bbb2-ea7a.in.ngrok.io"
//         return localhost;
// }
                    

 // const location = useLocation();

    // console.log('location',window.location.origin);

     // if(ServerURL.apiUrl === ''){
    //     await fetch(process.env.PUBLIC_URL + resourcesPath)
    //         .then((response) => (
    //             response.json()
    //             )).then((data) => {
    //                     let last = 0; // get last string
    //                     ServerURL.apiUrl = data.api_url;
    //                     last = data.api_url[data.api_url.length - 1];
    //                     if(last === '/')
    //                     {
    //                         ServerURL.apiUrl = data.api_url.substr(0, data.api_url.length - 1);
    //                     }
    //                     return ServerURL.apiUrl;
    //                 })   
    // }
    // return ServerURL.apiUrl;


    // const localhost = "http://192.168.29.194:8000"
    // const localhost = "http://127.0.0.1:8000"
     // return window.location.origin;