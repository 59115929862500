import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ResponsiveDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [isopenconfirm, setIsopenconfirm] = React.useState({'isOpen':false,'msg':''});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(()=>{
    const { isOpen, msg } = props.isopenconfirm;
        setIsopenconfirm({
            'isOpen' : isOpen,
            'msg' : msg,
        })
    setOpen(isOpen);
  },[props.isopenconfirm])

  const handleClick = (e) => {
    setOpen(false);
    props.parentConfirmCB(e.target.name === 'Ok')
  };

  function DisplayComponent(){
    return( <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
        <Dialog
            sx={fullScreen}
            open={open}
            onClose={handleClick}
            aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title">"Confirmation Message"</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {`Do you want to ${isopenconfirm.msg} the Information`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClick} color="primary" name='Cancel'>
          Cancel
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus name='Ok'>
          Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>)
  }

  return (
    <>
    { open ? <DisplayComponent /> : null }
    </>
  );
}
