import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as customerApi from '../api/customerApi';

export const getCustomer = createAsyncThunk("customer/getCustomer", async(name) => {
  const data = await customerApi.loadCustomers(name).then((res) => res.json()).then(responseData => responseData)
 
  return data;
});

export const createCustomer = createAsyncThunk("customer/createCustomer", async(values) => {
  const data = await customerApi.save(values).then((res) => res);
  return data;
});

export const updateCustomer = createAsyncThunk("customer/updateCustomer", async(values) => {
  const data = await customerApi.update(values).then((res) => res);
  return data;
});

export const deleteCustomer = createAsyncThunk("customer/deleteCustomer", async(values) => {
  const data = await customerApi.customerDelete(values).then((res) => res);
  return data;
});

const initialState = {
  customers: [],
  selectedCustomer:{
    'CustomerID':'',
    'CustomerName' :'',
    'RelationStatus' :'',
    'RelationName' :'',
    'Address1' :'',
    'Address2' :'',
    'Place' :'',
    'MobileNumber' :'',
    'Gender' :'',
    'Dob' :'',
    'AadharNumber' :'',
    'Email':'',
  },
  loadingStatus: 'noOption', // value = 'save'(create), 'read', 'update', 'delete'
  status: 0,
  error: null,
  edit:false,
  body: "",
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    edit: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    state.selectedCustomer.CustomerID = action.payload.selectedCustomer.CustomerID;
    state.selectedCustomer.CustomerName = action.payload.selectedCustomer.CustomerName;
    state.selectedCustomer.RelationStatus = action.payload.selectedCustomer.RelationStatus;
    state.selectedCustomer.RelationName = action.payload.selectedCustomer.RelationName;
    state.selectedCustomer.Address1 = action.payload.selectedCustomer.Address1;
    state.selectedCustomer.Address2 = action.payload.selectedCustomer.Address2;
    state.selectedCustomer.Place = action.payload.selectedCustomer.Place;
    state.selectedCustomer.MobileNumber = action.payload.selectedCustomer.MobileNumber;
    state.selectedCustomer.Gender = action.payload.selectedCustomer.Gender;
    state.selectedCustomer.Dob = action.payload.selectedCustomer.Dob;
    state.selectedCustomer.AadharNumber = action.payload.selectedCustomer.AadharNumber;
    state.selectedCustomer.Email = action.payload.selectedCustomer.Email;
    state.loadingStatus = 'edit';
    },
    // delet:(state, action)=>{
    //   console.log({state});
    //   console.log({action});
    //   // state.selectedCustomer.CustomerID = action.payload.selectedCustomer.CustomerID;
      
    //   state.loadingStatus = 'delete';
    //   console.log({state});
    //   console.log({action});
    // },
  },
  extraReducers:{
    [createCustomer.pending]: (state, action) => {
        state.status = 0;
      },
      [createCustomer.fulfilled]: (state, action) => {
        state.customers = action.payload;
        state.status = action.payload.status;
        state.loadingStatus = 'save';
    },
    [createCustomer.rejected]: (state, action) => {
        state.error = action.payload;
        state.status = 500;
    },
    [getCustomer.pending]: (state, action) => {
        state.status = 0;
    },
    [getCustomer.fulfilled]: (state, action) => {
        state.customers = action.payload;
        // state.status = action.payload.status;
        state.loadingStatus = 'read';
    },
    [getCustomer.rejected]: (state, action) => {
        state.error = action.payload;
        state.status = 500;
    },
    [updateCustomer.pending]: (state, action) => {
      state.status = 0;
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.customers = action.payload;
      state.status = action.payload.status;
      state.loadingStatus = 'update';
    },
    [updateCustomer.rejected]: (state, action) => {
        state.error = action.payload;
        state.status = 500;
    },
    [deleteCustomer.pending]: (state, action) => {
      state.status = 0;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      state.status = action.payload.status;
      state.loadingStatus = 'delete';
    },
    [deleteCustomer.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 500;
    },
    
    
}
  
})

// Action creators are generated for each case reducer function
export const {  edit, delet } = customerSlice.actions
export const selectCustomer = (state) => state.customers;
export default customerSlice.reducer