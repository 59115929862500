import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
//
// import Searchbar from './Searchbar';
// import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 240;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.92),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    display:'none',
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle >
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} >
        {/* <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}> */}
          <Icon icon={menu2Fill} style={{margin:'-10px'}}  />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        {/* <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}/> */}
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <AccountPopover /> */}
        {/* </Stack> */}
      </ToolbarStyle>
    </RootStyle>
  );
}










// import PropTypes from 'prop-types';
// import { Icon } from '@iconify/react';
// import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// // material
// import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
// import useMediaQuery from '@mui/material/useMediaQuery';
// // components
// import { MHidden } from '../../components/@material-extend';
// //
// // import Searchbar from './Searchbar';
// // import AccountPopover from './AccountPopover';
// // import LanguagePopover from './LanguagePopover';
// // import NotificationsPopover from './NotificationsPopover';

// // ----------------------------------------------------------------------

// const DRAWER_WIDTH = 480;
// const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//   boxShadow: 'none',
//   backdropFilter: 'blur(0px)',
//   WebkitBackdropFilter: 'blur(0px)', // Fix on Mobile
//   backgroundColor: alpha(theme.palette.background.default, 0),
//   // margin:'0px 3px 0px -15px !important',
//   margin: '0px !important',
//   padding: '5px !important',
//   display:'-webkit-inline-box',
//   // padding:'3px !important',
//   [theme.breakpoints.up('lg')]: {
//     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//     display:'none'

//   },
//   [theme.breakpoints.down('md')]: {
//     paddingTop:'0px !important',
//     paddingBottom:'0px !important',
//     display:'static',
//     height:'20px'
//   },
//   [theme.breakpoints.down('sm')]: {
//     paddingTop:'2px !important',
//     paddingBottom:'2px !important',
//   },
//   [theme.breakpoints.down('sx')]: {
//     paddingTop:'10px !important',
//     paddingBottom:'5px !important',
//   },
  
// }));

// const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
//   // minHeight: APPBAR_MOBILE,

//   [theme.breakpoints.up('lg')]: {
//     // minHeight: APPBAR_DESKTOP,
//     // padding: theme.spacing(0, 5),
//     display:'none'
//   },
//   [theme.breakpoints.down('lg')]: {
//     minHeight: `20px !important`,
//     // minHeight: `${APPBAR_MOBILE}px !important`,
//     // padding: theme.spacing(0, 5),
//     display:'-webkit-inline-box',
//     backgroundColor: 'orange',
//     paddingLeft: `5px !important`,
//     paddingRight: `5px !important`,
//   },
 
// }));

// // ----------------------------------------------------------------------

// DashboardNavbar.propTypes = {
//   onOpenSidebar: PropTypes.func
// };

// export default function DashboardNavbar({ onOpenSidebar }) {
//   return (
//     <RootStyle>
//       <ToolbarStyle >
//         <MHidden width="lgUp">
//             <Typography variant="Icon" mx={0} px={0} py={0} >
//           <IconButton onClick={onOpenSidebar} >
//             <Icon icon={menu2Fill} style={{margin:'-10px'}}  />
//           </IconButton>
//             </Typography>
//         </MHidden>
//         {/* <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
//           <Icon icon={menu2Fill} />
//         </IconButton> */}
//         {/* <Searchbar /> */}
//         <Box sx={{ flexGrow: 1 }} />

//         {/* <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
//           <LanguagePopover />
//           <NotificationsPopover />
//           <AccountPopover />
//         </Stack> */}
//       </ToolbarStyle>
//     </RootStyle>
//   );
// }
