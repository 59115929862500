import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Card, Grid, IconButton, Stack, Typography, Button } from '@material-ui/core';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import date from 'date-and-time';

// import SavingsIcon from '@mui/icons-material/Savings';
// import PriceChangeIcon from '@mui/icons-material/PriceChange';
// import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import StarsIcon from '@mui/icons-material/Stars';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { strToDate, todayDate, formatDateStrToYMD } from '../../../utils/formatDate';
// Api
import * as rewardApi from '../../../api/rewardApi';
// components
import Page from '../../Page';
import { getCustomer }from '../../../bo/customerBOSlice';
// import { getReward }from '../../../bo/rewardBOSlice';
// Util
import { handleRefreshDelay } from '../../../utils/handleRefresh';
import ResponsiveDialog from '../../common/ResponsiveDialog';
import PerformanceMsg from '../../common/PerformanceMsg';
// import CustomVirtualizeAutocomplete from '../../autocomplete/CustomVirtualizeAutocomplete';

export const rewardVariable = {
    'customerID' : 'customerID',
    'customerName' : 'customerName',
    'mobileNumber' : 'mobileNumber',
    'rewardOption' : 'rewardOption',
    'rewardValues' : 'rewardValues',
    'remarks' : 'remarks',
    'billNo' : 'billNo',
    'rewardDate' : 'rewardDate',
    'RewardID' : 'RewardID',

}
export const createReward = (values) => {
    const data = rewardApi.save(values).then((res) => res);
    return data;
}
export const updateReward = (values) => {
    console.log('house',{values})
    const data = rewardApi.update(values).then((res) => res);
    return data;
}
export default function Rewards(props) {

    const [saveUpdateButton, setSaveUpdateButton] = React.useState('Save');
    // conformation msg
    const [isopenconfirm, setIsopenconfirm] = React.useState({'isOpen':false,'msg':''});
    // performance msg
    const [performanceMessage, setPerformanceMessage] = React.useState({'isOpen':false,'msgType':'warning','msg':''});
    const [loading, setLoading] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const [fetchCustomerNameAfterID, setFetchCustomerNameAfterID] = React.useState(0);
    let errMsg = '';
    const labelPaddingTop = {paddingTop:'14px'};
    const todayDateValue = todayDate();
    const cust = useSelector(state=>state.customer);
    // const rewardLocal = useSelector(state=>state.reward);
    const dispatch = useDispatch();

    const [dd, setDd] = useState(`${todayDateValue.dd}` || '00');
    const [mm, setMm] = useState(`${todayDateValue.mm}` || '00');
    const [yyyy, setYyyy] = useState(`${todayDateValue.yyyy}` || '0000');
    const [rewardDate, setRewardDate] = useState('');
    const daylist=['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
    const monthlist=['00','01','02','03','04','05','06','07','08','09','10','11','12'];
    const yearlist=['0000','2001', '2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021','2022'];
    
    const [customerID, setCustomerID] = useState(0);
    const [customerName, setCustomerName] = useState('');
    // const [editCustomerName, setEditCustomerName] = useState(null);
    const [customerNameOptions, setCustomerNameOptions] = useState(['']);
    const [mobileNumberOptions, setMobileNumberOptions] = useState(['']);
    const [mobileNumber, setMobileNumber] = useState('');
    const [rewardOption, setRewardOption] = useState('earn');
    const [rewardValues, setRewardValues] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [billNo, setBillNo] = useState('');
    const [rewardID, setRewardID] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [editRow, setEditRow] = useState(null);
    const [rewardCollectionWithCustID, setRewardCollectionWithCustID] = useState(null);

    const [custNameErrorInput, setCustNameErrorInput] = React.useState({'customerName':false, 'customerNameLabel':""})
    const [mobileNumberErrorInput, setMobileNumberErrorInput] = React.useState({'mobileNumber':false, 'mobileNumberLabel':''})
    const [rewardValuesErrorInput, setRewardValuesErrorInput] = React.useState({'rewardValues':false, 'rewardValuesLabel':''})
    const [ddErrorInput, setDdErrorInput] = React.useState({'dd':false, 'ddLabel':'dd'});
    const [mmErrorInput, setMmErrorInput] = React.useState({'mm':false, 'mmLabel':'mm'});
    const [yyyyErrorInput, setYyyyErrorInput] = React.useState({'yyyy':false, 'yyyyLabel':'yyyy'});

    const [rewardCollection, setRewardCollection] = useState({
                                                                [rewardVariable.customerID] : '',
                                                                [rewardVariable.customerName] :'',
                                                                [rewardVariable.mobileNumber] :0,
                                                                [rewardVariable.rewardOption] : '',
                                                                [rewardVariable.rewardValues] : 0,
                                                                [rewardVariable.remarks] : '',
                                                                [rewardVariable.billNo] : 0,
                                                                [rewardVariable.rewardDate] : '',
                                                                [rewardVariable.RewardID] : 0,
                                                            });
    // customer name focus the input field
    let inputRef = React.useRef(null);
    
    useEffect(()=>{
        const strDate = strToDate(dd, mm, yyyy);
        setRewardDate(strDate);
    },[dd,yyyy,mm]);

    React.useEffect(()=>{
        setLoadingDelete(loadingDelete);
    },[loadingDelete]);

    React.useEffect(()=>{
        setCustomerName(customerName);
        if(customerName.length === 0)
        {
            setCustNameErrorInput({'customerName':true, 'customerNameLabel':'Enter Atleast 3 letters'});
            setTotalPoints(0);
        }
        else if(customerName.length > 0)
        {
            setCustNameErrorInput({'customerName':false, 'customerNameLabel':null});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    },[customerName]);

  

    React.useEffect(()=>{
        if(mobileNumber.length === 0)
        {
            setMobileNumberErrorInput({'mobileNumber':true, 'mobileNumberLabel':"Enter 10 digit's number"});
        }
        else if(Number.isNaN(mobileNumber) === true)
        {
            setMobileNumberErrorInput({'mobileNumber':true, 'mobileNumberLabel':"Enter 10 digit's number"});
        }
        else if(mobileNumber.length > 0)
        {
            setMobileNumberErrorInput({'mobileNumber':false, 'mobileNumberLabel':null});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    },[mobileNumber]);

    React.useEffect(()=>{
        if(mobileNumber.length === 0)
        {
            setRewardValuesErrorInput({'rewardValues':true, 'rewardValuesLabel':"Enter Reward Values"});
        }
        else if(mobileNumber.length > 0)
        {
            setRewardValuesErrorInput({'rewardValues':false, 'rewardValuesLabel':null});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    },[rewardValues]);

    const handleOnKeyPressDd = (e, ddValue) =>{
        if(ddValue.length === 0  || (ddValue.length === 1) || (ddValue.length > 2))
        {
            setDdErrorInput({'dd':true, 'ddLabel':"Enter date-00"});
        }
        else if(ddValue === '00'){
            setDdErrorInput({'dd':true, 'ddLabel':"Enter date-00"});
        }
        else if(ddValue.length > 0)
        {
            setDdErrorInput({'dd':false, 'ddLabel':'dd'});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    }

    const handleOnKeyPressMm = (e, mmValue) =>{
        if(mmValue.length === 0 || (mmValue.length === 1) || (mmValue.length > 2))
        {
            setMmErrorInput({'mm':true, 'mmLabel':"month-00"});
        }
        else if(mmValue === '00'){
            setDdErrorInput({'mm':true, 'mmLabel':"month-00"});
        }
        else if(mmValue.length > 0)
        {
            setMmErrorInput({'mm':false, 'mmLabel':'mm'});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    }

    const handleOnKeyPressYyyy = (e, yyyyValue) =>{
        if(yyyyValue.length === 0|| yyyyValue.length < 4 || yyyyValue.length > 4)
        {
            setYyyyErrorInput({'yyyy':true, 'yyyyLabel':"year-0000"});
        }
        else if(yyyyValue === '0000' ){
            setDdErrorInput({'yyyy':true, 'yyyyLabel':"year-0000"});
        }
        else if(yyyyValue.length > 0)
        {
            setYyyyErrorInput({'yyyy':false, 'yyyyLabel':'yyyy'});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    }

    React.useEffect(()=>{
        dispatch(getCustomer('list'));
    },[]);

    // React.useEffect(()=>{
    //     if(performanceMessage.msgType === 'success')
    //     {
    //         dispatch(getCustomer('list'));
    //     }
    //     // setPerformanceMessage({'isOpen':true,'msgType':'success','msg':'Update Successfully'});
    // },[performanceMessage]);

    React.useEffect(()=>{
        setLoading(loading);
    },[loading]);

    React.useEffect(()=>{
        setFetchCustomerNameAfterID(fetchCustomerNameAfterID);
    },[fetchCustomerNameAfterID]);


    // React.useEffect(()=>{
    //     console.log("fff customerID customerID",customerID)
    //     setCustomerID(customerID);
    // },[customerID]);

    React.useEffect(()=>{
        props.setGlobalCustomerID(customerID);
    },[customerID]);

    React.useEffect(()=>{
        if(!(customerName.length>0))
        {
            setCustomerID(0);
        }
        setTotalPoints(totalPoints);
    },[totalPoints, customerID, customerName]);

    React.useEffect(()=>{

        const result = customerNameOptions.find(({ CustomerID }) => CustomerID === customerID);
        if(customerID>0){
            rewardApi.loadReward(customerID)
            .then(res=>res.json())
            .then(res=>{
                setRewardCollectionWithCustID(res);
              return res
            }).catch((e)=>(console.log('e',e))); 
        }

        console.log({customerID})
        if(customerID ===0)
        {
            setTotalPoints(0);
        }
        
        if((typeof result !== 'undefined') && (result.CustomerID>0))
        {
            // setEditCustomerName(result);
            setCustomerName(result.CustomerName);
        }

    },[customerID]);


    React.useEffect(()=>{
        if(customerID>0){
            rewardApi.loadReward(customerID)
            .then(res=>res.json())
            .then(res=>{
                setRewardCollectionWithCustID(res);
              return res
            }).catch((e)=>(console.log('e',e))); 
        }

        if(rewardCollectionWithCustID !== null)
        {
            let totalPointsLocal = 0;
            rewardCollectionWithCustID.forEach(el=>{
                totalPointsLocal += el.RewardPoints;
            })
            setTotalPoints(totalPointsLocal);
        }
    },[rewardCollectionWithCustID, customerID])

    React.useEffect(()=>{
        setEditRow(props.editRow);
        if(editRow !== null)
        {
            setCustomerID(editRow.CustomerID);
            // setCustomerName(editRow.CustomerName);
            // update the customerId while fetch the edited row
            setFetchCustomerNameAfterID(editRow.CustomerID);
            setMobileNumber(editRow.MobileNumber);
            setRewardOption(editRow.RewardPoints < 0 ? 'redeem' : 'earn');
            setRewardValues(editRow.RewardPoints);
            setRemarks(editRow.Remarks);
            setBillNo(editRow.BillNumber);
            setRewardDate(editRow.RewardDate);
            setRewardID(editRow.RewardID);
            const resultDate = formatDateStrToYMD(editRow.RewardDate);
            setDd(resultDate.dd);
            setMm(resultDate.mm);
            setYyyy(resultDate.yyyy);
            setSaveUpdateButton('Update');
        }
        
        
      },[props.editRow, editRow]);

      
    function extractValue(arr, prop) {
        const extractedValue = arr.map(item => item[prop]);
        const removeDuplicateValue = extractedValue.filter((item,index) => extractedValue.indexOf(item) === index);
        return removeDuplicateValue;
    }
    
    React.useEffect(()=>{
        if((typeof cust.customers !== 'undefined') && (cust.customers.length > 0))
        {
            setCustomerNameOptions(cust.customers);
            setMobileNumberOptions(extractValue(cust.customers, 'MobileNumber'));
        }
    },[cust.customers]);

    const handleChange = (e, name) =>{
        const result = e.target.value;
        // it allow only number value
        const mobileNumberTest = /^[0-9\b]+$/;
        switch(name)
        {
            case 'dd':
                if(Number(result)<=31)
                {
                    setDd(result);
                }else{
                    setDd('');
                }
                break;
            case 'mm':
                if(Number(result)<=12)
                {
                    setMm(result);
                }else{
                    setMm('');
                }
                break;
            case 'yyyy':
                if(Number(result)>=1970 && Number(result)<=9999 )
                {
                    setYyyy(result);
                }else{
                    setYyyy('');
                }
                break;

            case 'customerName':
                setCustomerName(result);
                break;

            case 'mobileNumber':
                if ((result === '' || mobileNumberTest.test(result)) && (result.length <= 10)) {
                    setMobileNumber(result);
                }
                break;

            case 'rewardValues':
                if((result === '' || mobileNumberTest.test(result))) {
                        setRewardValues(result);
                    }
                else if(!mobileNumberTest.test(result))
                    {
                        setRewardValues('');
                    }
                break;

            case 'rewardOption':
                    setRewardOption(result);
                break;

            case 'remarks':
                    setRemarks(result);
                break;

            case 'billNo':
                    setBillNo(result);
                break;

            default:
        }
    }

    

    const handleCustomerName = (e, value, name)=>{
      
        switch(name)
        {
            case "customerName":
                if((typeof value.CustomerName !== 'undefined') && (value.CustomerName.length > 0))
                {
                    setCustomerName(value.CustomerName);
                    setMobileNumber(value.MobileNumber);
                    setCustomerID(value.CustomerID);
                    setFetchCustomerNameAfterID(value.CustomerID);
                }
                else{
                    setCustomerName(value);
                    setCustomerID(0);
                }
                break;

            case "mobileNumber":
                if((typeof value.MobileNumber !== 'undefined') && (value.MobileNumber.length > 0))
                {
                    setCustomerName(value.CustomerName);
                    setMobileNumber(value.MobileNumber);
                    setCustomerID(value.CustomerID);
                    setFetchCustomerNameAfterID(value.CustomerID);
                }
                else{
                    setCustomerID(0);
                }
                break;

            default:
        }
}

    const validation = (value) =>{
        let resultStatus = false;
        let validCustName = false;
        let validMobileNumber = false;
        let validateDate =false;
        let validateRewardValue = false;
        // const validateRewardValue = rewardValues > 0 ? true : false;
        const mobileNumberTest = /^[0-9\b]+$/;
        // let validDate = false;
        if(value.customerName.length > 2)
        {
            validCustName= true ;
        }else{
            errMsg += '- Fill the Customer Name';
            validCustName= false;
        }
       
        if( (value.mobileNumber.length <= 10) && !(mobileNumberTest.test(value.mobileNumber)) && (value.mobileNumber.length > 10))
        {
                validMobileNumber = false;
            errMsg += '\n- Enter 10-digit number for Mobile Number ';
        }
        else{
            validMobileNumber = true;
        }
        
        if(rewardValues <= 0 )
        {
            validateRewardValue = false;
            errMsg += '\n- Must enter Reward Value ';
        }
        else{
            validateRewardValue = true;
        }
       // validate the date of birth
        if(value.rewardDate !== '0000-00-00')
        {
           const validDate = date.isValid(`${value.rewardDate}`, 'YYYY-MM-DD');
            if(validDate === true){
                validateDate = true;
            }else{
                validateDate = false;
                errMsg += '\n- Enter valid Date';
                setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`${errMsg}`});
            }

        }
        
        if(validCustName === false || validMobileNumber === false || validateDate === false || validateRewardValue === false)
        {
            resultStatus = false;
            setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`${errMsg}`});
        }else{
            resultStatus = true;
            
            
        }
        return resultStatus;
    }

       
    
    const submitSave = () =>{
        createReward(rewardCollection)
        .then(res=>
            {
            console.log({res});

            if(res.status === 200)
            {
                setPerformanceMessage({'isOpen':true,'msgType':'success','msg':'Save Successfully'});
                setFetchCustomerNameAfterID(0);
                setCustomerName('');
                setMobileNumber('');
                setRewardOption('earn');
                setRewardValues(0);
                setRemarks('');
                setBillNo('');
                setRewardID(0);
                setDd(`${todayDateValue.dd}`);
                setMm(`${todayDateValue.mm}`);
                setYyyy(`${todayDateValue.yyyy}`);
                dispatch(getCustomer('list'));
                // inputRef.current.focus();
                // handleRefreshDelay();
            }
            else{
                setPerformanceMessage({'isOpen':true,'msgType':'error','msg':'Error while save information'});
            }
        });
    }

    const submitUpdate = () =>{
        if(rewardID > 0){
            updateReward(rewardCollection)
            .then(res=>
                {
                console.log({res});
                if(res.status === 200){
                    res.text().then((text)=> {
                        if(text === 'Updated Succefully')
                        {
                            setPerformanceMessage({'isOpen':true,'msgType':'success','msg':'Update Successfully'});
                            setFetchCustomerNameAfterID(0);
                            setCustomerName('');
                            setMobileNumber('');
                            setRewardOption('earn');
                            setRewardValues(0);
                            setRemarks('');
                            setBillNo('');
                            setRewardID(0);
                            setDd(`${todayDateValue.dd}`);
                            setMm(`${todayDateValue.mm}`);
                            setYyyy(`${todayDateValue.yyyy}`);
                            dispatch(getCustomer('list'));
                            // inputRef.focus();
                            // handleRefreshDelay();
                        }
                        else{
                            setPerformanceMessage({'isOpen':true,'msgType':'error','msg':'Error while save information'});
                        }});
                    }
                 });
            }
    }

    const handleSave = (e) =>{
        rewardCollection.customerID = customerID;
        rewardCollection.customerName = customerName;
        rewardCollection.mobileNumber = mobileNumber;
        rewardCollection.rewardOption = rewardOption;
        rewardCollection.rewardValues = rewardOption==='earn' ? rewardValues : rewardValues*-1;
        rewardCollection.remarks = remarks;
        rewardCollection.billNo = billNo;
        rewardCollection.rewardDate = strToDate(dd, mm, yyyy);
        setIsopenconfirm({'isOpen':true,'msg':'Save'});

    }

    const handleUpdate = (e) =>{
        rewardCollection.customerID = customerID;
        rewardCollection.customerName = customerName;
        rewardCollection.mobileNumber = mobileNumber;
        rewardCollection.rewardOption = rewardOption;
        rewardCollection.rewardValues = rewardOption==='earn' ? rewardValues : rewardValues*-1;
        rewardCollection.remarks = remarks;
        rewardCollection.billNo = billNo;
        rewardCollection.rewardDate = strToDate(dd, mm, yyyy);
        rewardCollection.RewardID = rewardID;

        setIsopenconfirm({'isOpen':true,'msg':'Update'});

    }

    const handleSubmit = (e, value) =>{
        switch(value){
            case 'Save':
                handleSave();
                break;
            case 'Update':
                handleUpdate();
                break;
            default:
                break;
       }
    }

    function handleClickDelete() {
        setLoadingDelete(true);
        setFetchCustomerNameAfterID(0);
        setCustomerName('');
        setMobileNumber('');
        setRewardOption('earn');
        setRewardValues(0);
        setRemarks('');
        setBillNo('');
        setRewardID(0);
        setDd(`${todayDateValue.dd}`);
        setMm(`${todayDateValue.mm}`);
        setYyyy(`${todayDateValue.yyyy}`);
        setLoadingDelete(false);
        inputRef.focus();
    }

    function parentConfirmCB(value){
        if(value){
           if(validation(rewardCollection))
           {
               setLoading(true);
               if(rewardID > 0)
               {
                submitUpdate();
               }else{
                submitSave();
               }
               setLoading(false);
           }
        }
        else{
            setLoading(false);
        }
    }
    // const [ddErrorInput, setDdErrorInput] = React.useState({'dd':false, 'ddErrorLabel':'dd'});
    // const [ddErrorInput, setDdErrorInput] = React.useState({'dd':false, 'ddErrorLabel':'dd'});
    const daysComponent = <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            value={dd || '00'}
                            options={daylist.map((option) => option)}
                            disableClearable
                            renderInput={(params) => 
                                        <TextField {...params} 
                                         variant="outlined"  
                                        type='number'
                                        label={ddErrorInput.ddLabel}
                                        error={ddErrorInput.dd}
                                        onKeyPress={(e)=>handleOnKeyPressDd(e ,e.target.value)}
                                        // onInputCapture={(e)=>handleOnKeyPressDd(e ,e.target.value)}
                                        onChange={(e)=>{handleChange(e,"dd");handleOnKeyPressDd(e, e.target.value);}}
                                        onBlur={(e)=>{handleChange(e,"dd");handleOnKeyPressDd(e, e.target.value);}}
                                        />
                                    }
                            // onChange={(e,value)=>handleOnKeyPressDd(e,value)}
                        />;
        

    const monthsComponent = <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={mm || '00'}
                                options={monthlist.map((option) => option)}
                                disableClearable
                                renderInput={(params) => <TextField 
                                                                {...params} 
                                                                // label="mm" 
                                                                label={mmErrorInput.mmLabel}
                                                                error={mmErrorInput.mm}
                                                                type='number' 
                                                                variant="outlined"
                                                                onKeyPress={(e)=>handleOnKeyPressMm(e, e.target.value)}
                                                                onChange={(e)=>{handleChange(e,"mm"); handleOnKeyPressMm(e, e.target.value)}}
                                                                onBlur={(e)=>{handleChange(e,"mm"); handleOnKeyPressMm(e, e.target.value)}}
                                                            />}
                            />;

    const yearsComponent = <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                value={yyyy || '0000'} 
                                options={yearlist.map((option) => option)}
                                disableClearable
                                renderInput={(params) => <TextField 
                                                                {...params} 
                                                                type='number' 
                                                                label={yyyyErrorInput.yyyyLabel}
                                                                error={yyyyErrorInput.yyyy}
                                                                variant="outlined" fullWidth 
                                                                onKeyPress={(e)=>handleOnKeyPressYyyy(e, e.target.value)}
                                                                onChange={(e)=>{handleChange(e,"yyyy");handleOnKeyPressYyyy(e, e.target.value)}}
                                                                onBlur={(e)=>{handleChange(e,"yyyy");handleOnKeyPressYyyy(e, e.target.value)}}
                                                            />}
                            />;

    const remarkComponent = <TextField  
                                id="remarks" 
                                fullWidth 
                                variant="outlined"
                                value={remarks}
                                onChange={(e)=>{handleChange(e,'remarks')}} 
                            />;

    const billnoComponent = <TextField 
                                id="billNo" 
                                fullWidth 
                                variant="outlined"
                                value={billNo=== 0 ? 0 : billNo}
                                onChange={(e)=>{handleChange(e,'billNo')}}
                            />;

    const filterOptions = createFilterOptions({
                            matchFrom: 'start',
                            stringify: (option) => `${option.CustomerName}`,
                        });
                        
    const customerNameComponent = <Autocomplete
                                        id="customerName"
                                        freeSolo
                                        options={customerNameOptions}
                                        autoHighlight
                                        filterOptions={filterOptions}
                                        value={customerName}
                                        // value={editCustomerName}
                                        // getOptionLabel={(option) => `${option.MobileNumber}`}
                                        renderOption={(props, option) => (
                                            // <li {...props} key={option.id}>
                                            //     `${option.CustomerName} - ${option.Place}``
                                            //     `${option.MobileNumber}`
                                            // </li>
                                            <Stack component="li" {...props} key={option.id}>
                                                <Typography sx={{width:'100%'}} >
                                                    {option.CustomerName} - {option.Place}
                                                </Typography>
                                                <Typography sx={{width:'100%'}} >
                                                    {option.MobileNumber}
                                                </Typography>
                                            </Stack>
                                          )}
                                        renderInput={(params) => (
                                        <TextField {...params} variant="outlined"
                                            // ref={inputRef}
                                            // inputProps={{ref:inputRef}}
                                            inputRef={input => {
                                                inputRef = input;
                                            }}
                                            label={custNameErrorInput.customerNameLabel}
                                            error={custNameErrorInput.customerName} 
                                            onChange={(e)=>handleCustomerName(e, e.target.value, "customerName")}
                                            onBlur={(e)=>handleCustomerName(e, e.target.value, "customerName")}
                                            autoFocus
                                         />
                                         )}
                                        onChange={(e, value)=>
                                            {
                                                if(value !== null)
                                                {
                                                    handleCustomerName(e, value, "customerName")
                                                }
                                            }}
                                        />;
        const handleonInputChange = (event,value,reason) => {
            if(value === 's' && reason === 'input')
            {

                console.log("ffffffffffffff",event);
                console.log("ffffffffffffff value",value);
                console.log("ffffffffffffff reason",reason);
                setMobileNumber('ggg');
                // return reason = 'clear';
            }
        }
        const filterOptionsMobileNumber = createFilterOptions({
                                    matchFrom: 'start',
                                    stringify: (option) => `${option.MobileNumber}`,
                                });
        // const inputProps = {
        //     step: 300,
        //     };                    
        const mobileNumberComponent = <Autocomplete
                                            id="mobileNumber"
                                            freeSolo
                                            options={customerNameOptions}
                                            autoHighlight
                                            filterOptions={filterOptionsMobileNumber}
                                            value={mobileNumber}
                                            onInputChange={(event,value,reason)=>handleonInputChange(event,value,reason)}
                                            // value={Number(mobileNumber) ? mobileNumber : setMobileNumber('')}
                                            // value={editCustomerName}
                                            renderOption={(props, option) => (
                                                <Stack component="li" {...props}>
                                                    <Typography sx={{width:'100%'}} >
                                                        {`${option.MobileNumber}`}
                                                    </Typography>
                                                    <Typography sx={{width:'100%'}} >
                                                        {`${option.CustomerName} - ${option.Place}`}
                                                    </Typography>
                                                </Stack>
                                                )}
                                            renderInput={(params) => {
                                                console.log("params",params);
                                                return <TextField {...params} variant="outlined"
                                                    label={mobileNumberErrorInput.mobileNumberLabel}
                                                    error={mobileNumberErrorInput.mobileNumber}
                                                    onChange={(e)=>{
                                                        handleChange(e,'mobileNumber')
                                                    }}
                                                    onBlur={(e)=>{handleChange(e,'mobileNumber')}}
                                                />
                                            }}
                                            onChange={(e, value)=>
                                                {
                                                    console.log('value',value);
                                                    if(value !== null)
                                                    {
                                                        handleCustomerName(e, value, "mobileNumber")
                                                    }
                                                }}
                                            />;
                                        
    // const mobileNumberComponent =  <Autocomplete
    //                                     id="mobileNumber"
    //                                     freeSolo
    //                                     options={mobileNumberOptions}
    //                                     disableClearable
    //                                     value={mobileNumber}
    //                                     renderInput={(params) => 
    //                                                             <TextField 
    //                                                                 {...params} 
    //                                                                 label={mobileNumberErrorInput.mobileNumberLabel}
    //                                                                 error={mobileNumberErrorInput.mobileNumber}
    //                                                                 onChange={(e)=>{handleChange(e,'mobileNumber')}}
    //                                                                 onBlur={(e)=>{handleChange(e,'mobileNumber')}}
    //                                                             />
    //                                                         }
    //                                     onChange={(e, value)=> {
    //                                         const mobileNumberTest = /^[0-9\b]+$/;
    //                                         if((value !== null) && (mobileNumberTest.test(value)) )
    //                                         {
    //                                             handleCustomerName(e, value, "mobileNumber")
    //                                         }
    //                                     }}
    //                                 />;

    const rewardPointComponent = <FormControl>
                                    <FormLabel id="rewardPoint" />
                                        <RadioGroup
                                        row
                                        aria-labelledby="rewardPoint"
                                        name="radio-buttons-group"
                                        value={rewardOption}
                                        onChange={(e)=>{handleChange(e, 'rewardOption')}}
                                        >
                                            <FormControlLabel value="earn" control={<Radio />} label="Earn" />
                                            <FormControlLabel value="redeem" control={<Radio />} label="Redeem" />
                                        </RadioGroup>
                                </FormControl>;

    const rewardValueComponent = <TextField 
                                    fullWidth
                                    label={rewardValuesErrorInput.rewardValuesLabel}
                                    error={rewardValuesErrorInput.rewardValues}
                                    value={rewardValues=== 0 ? 0 : rewardValues}
                                    onChange={(e)=>{handleChange(e,'rewardValues')}}
                                />;

    const saveButtonComponent =  <LoadingButton
                                    size="large"
                                    color="primary"
                                    onClick={(e)=>handleSubmit(e, saveUpdateButton)}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    fullWidth
                                    >
                                    {saveUpdateButton}
                                </LoadingButton>

    const cancelButtonComponent =  <LoadingButton
                                        size="large"
                                        color="error"
                                        onClick={handleClickDelete}
                                        loading={loadingDelete}
                                        loadingPosition="start"
                                        startIcon={<DeleteIcon />}
                                        variant="contained"
                                        fullWidth
                                        >
                                        Cancel
                                    </LoadingButton>
   

    const star = <span >*</span>;

    const handleIconViewAll = (e, name) =>{

        const strDate = strToDate(dd, mm, yyyy);
        switch(name){
            case 'customerNameIcon':
                if(props.tabValue === 1 && ((customerID > 0) || (fetchCustomerNameAfterID > 0))){
                    props.setTabValue(2);
                    const localCustomerID = (fetchCustomerNameAfterID > 0) ? fetchCustomerNameAfterID : customerID;
                    props.setGlobalCustomerID(localCustomerID);
                }
                break;
            case 'dateIcon':
                if(props.tabValue === 1 ){
                    props.setTabValue(2);
                    props.setGlobalDate(strDate);
                    props.setGlobalCustomerID(0);
                    dispatch(getCustomer('list'));
                }
                break;
            default:
                break;
        }
    }

    return(
        <>
        
        <Page title="Master : Remarks | Meenakshi Solution">
            <ResponsiveDialog isopenconfirm={isopenconfirm} parentConfirmCB={parentConfirmCB}/>
            <PerformanceMsg performanceMessage={performanceMessage} setPerformanceMessage={performanceMessage} />
            <Card>
                <div style={{padding:'20px 20px'}}>
                    <Grid container spacing={2} py={4} >
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Grid container spacing={1} px={2}>
                                {/* row 1 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    {/* Date:{star} */}
                                    <h4 style={labelPaddingTop} >Date:{star}</h4>
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={3}>
                                    {daysComponent}
                                </Grid>
                                <Grid item xs={3} sm={3} md={2} lg={2}>
                                    {monthsComponent}
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={3}>
                                    {yearsComponent}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                    <div style={{padding:"5px 0px"}}>
                                    <Button color="primary"  id="dateIcon" tabIndex={-1} onClick={(e)=>handleIconViewAll(e, "dateIcon")} aria-label="upload picture" component="label">
                                        <ViewListIcon fontSize="large" />
                                    </Button>
                                    </div>
                                </Grid>

                                {/* row 2 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >Customer Name:{star}</h4>
                                </Grid>
                                <Grid item xs={11} sm={11} md={8} lg={8}>
                                    {customerNameComponent}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                    <div style={{padding:"5px 0px"}}>
                                    <Button color="primary" id="customerNameIcon" tabIndex={-1} onClick={(e)=>handleIconViewAll(e, "customerNameIcon")} aria-label="upload picture" component="label">
                                        <ViewListIcon fontSize="large"  />
                                    </Button>
                                    </div>
                                </Grid>

                                {/* row 3 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >Mobile Number:{star}</h4>
                                </Grid>
                                <Grid item xs={11} sm={11} md={8} lg={8}>
                                    {mobileNumberComponent}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} />

                                {/* row 4 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >Total Points:</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <div style={{ backgroundColor:'floralwhite'}}>
                                        <h3 style={{padding:'12px', color:'red'}}>
                                            {customerName.length > 0 ? totalPoints : 0}
                                        </h3>
                                    </div>
                                </Grid>
                                <Grid item md={5} lg={5} />
                                {/* <Grid item xs={1} sm={1} md={5} lg={5} >
                                    <div style={{padding:'10px', color:'#ffb900'}}>
                                        <StarsIcon />    
                                    </div>
                                </Grid> */}

                                {/* <Grid item xs={12} sm={12} md={12} lg={12} /> */}

                                {/* row 5 */}
                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                    <h4 style={labelPaddingTop} >Reward Types:</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}  >
                                    <div style={{padding:"8px"}}>
                                        {rewardPointComponent}
                                    </div>
                                </Grid>

                                {/* <Grid item xs={12} sm={12} md={12} lg={12} /> */}

                                {/* row 6 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >Reward Value:{star}</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    {rewardValueComponent}
                                </Grid>
                                <Grid item md={5} lg={5} />

                               
                                {/* row 7 */}
                                

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} >
                            <Grid container spacing={1} px={2}>
                                {/* row 1 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >CustomerID:</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <div style={{ backgroundColor:'floralwhite',padding:'18px', color:'red'}}>
                                        {/* <h4 style={{}}> */}
                                            {fetchCustomerNameAfterID > 0 ? fetchCustomerNameAfterID : customerID}
                                        {/* </h4> */}
                                    </div>
                                </Grid>
                                <Grid item md={5} lg={5} />

                                {/* row 2 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >Remarks:</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    {remarkComponent}
                                </Grid>

                                {/* row 3 */}
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <h4 style={labelPaddingTop} >Bill No:</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    {billnoComponent}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} py={15} />

                                <Grid item xs={6} sm={6} md={6} lg={6} >
                                    {saveButtonComponent}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} >
                                    {cancelButtonComponent}
                                    {/* <Button fullWidth color='error' size="large" variant="contained" >Cancel</Button> */}
                                </Grid>



                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </Page>
        </>
    )
}


