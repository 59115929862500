import { configureStore } from '@reduxjs/toolkit';
import customerBOReducer from '../bo/customerBOSlice';
// import rewardBOReducer from '../bo/rewardBOSlice';

export const store = configureStore({
  reducer: {
    customer: customerBOReducer,
    // reward: rewardBOReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
 // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})