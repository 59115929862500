import * as React from 'react';
import {Typography} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


// component
import { CustomerDetails, CustomerDataGrid } from '../components/_dashboard/customerDetails';


export default function CustomerRewards() {
  const [tabValue, setTabValue] = React.useState(1);


  React.useEffect(()=>{
    setTabValue(tabValue);
  },[tabValue])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  
  return (
    <>
      <Typography variant="h3" gutterBottom sx={{ textAlign:'center'}}>
          Customers
      </Typography>
    <Box sx={{width: '100%' }}>
    
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value={1} label="Customer Details" />
        <Tab value={2} label="View All" />
      </Tabs>
      {(tabValue === 1)? <CustomerDetails /> : <CustomerDataGrid tabValue={tabValue} setTabValue={setTabValue} />}
    </Box>
    
    </>
  );
}
