import { getHostConfig } from '../utils/serverConfig';
import { requestPostOptions } from './requestOptions';


// fetch Customer Collection
export async function loadCustomers(name){
  return new Promise((resolve, reject)=>{
    getHostConfig().then(async (dataval) => 
    {
      await fetch(`${dataval}/customer/list`)
      .then((response) => (resolve(response)))
      .catch((error)=> reject(error))
    })
  });
    // return getHostConfig().then(async data =>{
    //   await fetch(`${data}/customer/${name}`).then((response)=>response.json())
    //   .then((resposeData) => resposeData)
    //   .catch(error => console.warn(error))
    // });
}

// update api
export async function update(customer){
    return new Promise((resolve, reject)=>{
      getHostConfig().then(async (dataval) => {
        await fetch(`${dataval}/customer/update`, requestPostOptions(customer))
        .then((response) => (resolve(response)))
        .catch((error)=> reject(error));
    })
  });
}

// delete api
export async function customerDelete(customer) {
  return new Promise((resolve, reject)=>{
    getHostConfig().then(async (dataval) => 
    {
      await fetch(`${dataval}/customer/delete`, requestPostOptions(customer))
      .then((response) => (resolve(response)))
      .catch((error)=> reject(error))
    })
  });
}

// save api
export async function save(customer){
    return new Promise((resolve, reject)=>{
      getHostConfig().then(async dataval => {
        await fetch(`${dataval}/customer/save`, requestPostOptions(customer))
        .then((response) => resolve(response))
        .catch((error)=> reject(error));
    })
  });
}

// Previous code

// fetch Customer Collection
// export async function loadCustomers(name){
  
//     return getHostConfig().then(data => fetch(`${data}/customerDetails/backend/index.php/${name}`)
//     .then((response)=> response.json())
//     .then((resposeData) =>{
//         console.log('searchCustomerApi',resposeData);
//         return resposeData;
//     })
//     .catch(error => console.warn(error))
//     );
// }

// export async function customerDelete(customer){
  
//     return new Promise((resolve, reject)=>{
      
//       getHostConfig().then((dataval) => {
        
//         // const requestOptions = {
//         //   method: 'POST',
//         //   headers: { 'Content-Type': 'text/plain' },
//         //   body: JSON.stringify(customer)
//         // };
        
//         return fetch(`${dataval}/customerDetails/backend/index.php/delete`, requestPostOptions(customer))
//         .then((response) => {
//           console.log({response});
//           return resolve(response);
//         }
//           )
//         .catch((error)=> reject(error));
//     })
//   });
// }

// export async function update(customer){
//     return new Promise((resolve, reject)=>{
//       getHostConfig().then((dataval) => {
        
//         // const requestOptions = {
//         //   method: 'POST',
//         //   headers: { 'Content-Type': 'text/plain' },
//         //   body: JSON.stringify(customer)
//         // };

//         return fetch(`${dataval}/customerDetails/backend/index.php/update`, requestPostOptions(customer))
//         .then((response) => {
//           console.log({response});
//           return resolve(response)
//         })
//         .catch((error)=> reject(error));
//     })
//   });
// }


// const value = [{ 'RewardID' : 1, 'CustomerID' : 1, 'RewardDate' : date("Y-m-d H:i:s"), 'Remarks' : 'Remarks', 'BillNumber' : 100 ,'RewradPoints':102}];
// saveReward(value);