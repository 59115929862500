import { useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// components
// import DashboardApp from './pages/DashboardApp';
import Customer from './pages/Customer';
import CustomerRewards from './pages/CustomerRewards';
// import {Rewards} from './components/_dashboard/Rewards';
import Login from './pages/Login';

// import { DashboardListItem } from './components/_dashboard/dashboardlistitem';


// ----------------------------------------------------------------------

export default function Router() {

  const element = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children:[
        {
          path: 'customerDetails',
          element: <Customer />,
        },
        {
          path: 'Rewards',
          element: <CustomerRewards />,
        },
      ]
     }
    // {
    //   path: 'login',
    //   element: <Login />,
    // },
  ]);

  return element;
  
}
