import { getHostConfig } from '../utils/serverConfig';
import { requestPostOptions } from './requestOptions';


// save api
export async function save(customer){
    return new Promise((resolve, reject)=>{
      getHostConfig().then(async dataval => {
        await fetch(`${dataval}/reward/save`, requestPostOptions(customer))
        .then((response) => resolve(response))
        .catch((error)=> reject(error));
    })
  });
}

// fetch Rewards Collection
export async function loadReward(value){
  return new Promise((resolve, reject)=>{
    getHostConfig().then(async dataval => {
      // console.log("222222222222",`${dataval}/customerDetails/backend/index.php/reward/list?value=${value}`)
      console.log("dataval", dataval);
      await fetch(`${dataval}/reward/list?value=${value}`)
      .then((response) => resolve(response))
      .catch((error)=> reject(error));
  })
});
}

export async function update(reward){
  alert('update api 1111111',reward);
  return new Promise((resolve, reject)=>{
    getHostConfig().then(async (dataval) => {
      await fetch(`${dataval}/reward/update`, requestPostOptions(reward))
      .then((response) => (resolve(response)))
      .catch((error)=> reject(error));
  })
});
}

// delete api
export async function rewardDelete(rewardID) {
  return new Promise((resolve, reject)=>{
    getHostConfig().then(async (dataval) => 
    {
      // console.log("222222222222 dddddddddddd",`${dataval}/customerDetails/backend/index.php/reward/delete`)
      await fetch(`${dataval}/reward/delete`, requestPostOptions(rewardID))
      .then((response) => (resolve(response)))
      .catch((error)=> reject(error))
    })
  });
}