import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
// import navConfig from './NavConfig';
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 240;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  // [theme.breakpoints.down('lg')]: {
  //   // display:'none',
  //   width: DRAWER_WIDTH,
  // },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              Hi, Admin
                {/* {account.displayName} */}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      {/* <NavSection navConfig={navConfig} /> */}
      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}

          {/* <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}












// import PropTypes from 'prop-types';
// import { useEffect } from 'react';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
// // material
// import { experimentalStyled as styled } from '@material-ui/core/styles';
// import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@material-ui/core';
// // components
// import Logo from '../../components/Logo';
// import Scrollbar from '../../components/Scrollbar';
// import NavSection from '../../components/NavSection';
// import { MHidden } from '../../components/@material-extend';
// //
// import sidebarConfig from './SidebarConfig';
// import account from '../../_mocks_/account';


// // ----------------------------------------------------------------------

// const DRAWER_WIDTH_LARGE = 250;
// const DRAWER_WIDTH = 80;

// // sidemenu bar width Adjustment 1
// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('lg')]: {
//     flexShrink: 0,
//     width: DRAWER_WIDTH,
//   //   '&:hover': {
//   //     width: DRAWER_WIDTH_LARGE,
//   //  },
//   },
 
// }));

// // adjustment for profile icon adjustment
// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(1, 1),
//   borderRadius: theme.shape.borderRadiusSm,
//   // backgroundColor: theme.palette.grey[200]
// }));

// // ----------------------------------------------------------------------

// DashboardSidebar.propTypes = {
//   isOpenSidebar: PropTypes.bool,
//   onCloseSidebar: PropTypes.func,
//   // onOpenSidebar: PropTypes.func
// };

// export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     if (isOpenSidebar) {
//       onCloseSidebar();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [pathname]);

//   const renderContent = (
    
//     <Scrollbar
//       sx={{
//         height: '100%',
//         '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
//       }}
//     >
     
//       <Box sx={{ px: 2, py: 2.5 }}>
//         <Box component={RouterLink} to="/" sx={{ display: 'inline-block' }}>
//           <Logo />
//         </Box>
//       </Box>

//       <Box sx={{ mb: 5, mx: 2.5 }}>
//         <Link underline="none" component={RouterLink} to="#">
//           <AccountStyle>
//             <Avatar src={account.photoURL} alt="photoURL" />
//             <Box sx={{ ml: 2 }}>
//               <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
//                 Hi, Admin
//                 {/* {account.displayName} */}
//               </Typography>
//               <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//                 {account.role}
//               </Typography>
//             </Box>
//           </AccountStyle>
//         </Link>
//       </Box>

//       <NavSection navConfig={sidebarConfig} />
//       {/* <NavSection navConfig={sidebarConfig} /> */}

//       <Box sx={{ flexGrow: 1 }} />

//       {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
//         <Stack
//           alignItems="center"
//           spacing={3}
//           sx={{
//             p: 2.5,
//             pt: 5,
//             borderRadius: 2,
//             position: 'relative',
//             bgcolor: 'grey.200'
//           }}
//         >
//           <Box
//             component="img"
//             src="/static/illustrations/illustration_rocket.png"
//             sx={{ width: 100, position: 'absolute', top: -50 }}
//           /> */}

//           {/* <Box sx={{ textAlign: 'center' }}>
//             <Typography gutterBottom variant="h6">
//               Get more?
//             </Typography>
//             <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//               From only $69
//             </Typography>
//           </Box> */}

//           {/* <Button
//             fullWidth
//             href="https://material-ui.com/store/items/minimal-dashboard/"
//             target="_blank"
//             variant="contained"
//           >
//             Upgrade to Pro
//           </Button> */}
//         {/* </Stack>
//       </Box> */}
//     </Scrollbar>
//   );

//   return (
//     <RootStyle>
//       <MHidden width="lgUp">
//         <Drawer
//           // onClick={isOpenSidebar}
//           open={isOpenSidebar}
//           onClose={onCloseSidebar}
//           // sidemenu bar width Adjustment 2
//           PaperProps={{
//             sx: { 
//               width: DRAWER_WIDTH, 
//               '&:hover': {
//                 width: DRAWER_WIDTH_LARGE,
//               },
//             }
            
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       </MHidden>
//       <MHidden width="lgDown">
//         <Drawer
//           open
//           variant="persistent"
//         // sidemenu bar width Adjustment 3
//           PaperProps={{
//             sx: {
//               width: DRAWER_WIDTH,
//               '&:hover': {
//                 width: DRAWER_WIDTH_LARGE,
//               },
//               bgcolor: 'background.default'
//             }
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       </MHidden>
//       {/* <MHidden width="lgDown">
//         <Drawer
//           open
//           variant="persistent"
//           PaperProps={{
//             sx: {
//               width: DRAWER_WIDTH,
//               bgcolor: 'background.default'
//             }
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       </MHidden> */}
//     </RootStyle>
//   );
// }
