// routes
import Router from './routes';
// import { BrowserRouter as Route, Routes } from 'react-router-dom';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import DashboardLayout from './layouts/dashboard';
import Customer from './pages/Customer';
import CustomerRewards from './pages/CustomerRewards';


// ----------------------------------------------------------------------
// export default function App() {
//   return (
//       <h1>Hello My Dear Kutti Sattan</h1>
//   );
// }

export default function App() {
  return (
      <ThemeConfig >
        <ScrollToTop />
             <Router />
      </ThemeConfig>
  );
}

