import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { DataGrid, gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import PerformanceMsg from '../../common/PerformanceMsg';
import ResponsiveDialog from '../../common/ResponsiveDialog';
// import { getReward } from '../../../bo/rewardBOSlice';
// import { edit, deleteCustomer } from '../../../bo/customerBOSlice';
// util
import { handleRefreshDelay } from '../../../utils/handleRefresh';
import { todayDate, dateFormatForDataGridColumn } from '../../../utils/formatDate';
// Api
import * as rewardApi from '../../../api/rewardApi';



export default function RewardsDataGrid(props) {
  // const [fetchAllCustomer, setFetchAllCustomer]   = useState([]);
  const [fetchRewards, setFetchRewards]   = useState([]);
  const [deleteID, setDeleteID]  = useState(0);
  
  // conformation msg
  const [isopenconfirm, setIsopenconfirm] = useState({'isOpen':false,'msg':''});

  // performance msg
  const [performanceMessage, setPerformanceMessage] = useState({'isOpen':false, 'msgType':'warning', 'msg':''});

  const [globalCustomerID, setGlobalCustomerID] = React.useState(0);
  const [globalDate, setGlobalDate] = React.useState(0);
// let fetchRewards =[];




// fetch records from Rewards
  const fetchDetails = (value) =>( rewardApi.loadReward(value)
    .then(res=>res.json())
    .then(res=>res)
    .catch((e)=>(console.log('e',e)))
  )


useEffect(()=>{
  setFetchRewards(props.fetchRewards);
},[props.fetchRewards])

useEffect(()=>{
  setGlobalDate(props.globalDate);
},[props.globalDate])

  useEffect(()=>{
       if(globalCustomerID > 0) {
      fetchDetails(globalCustomerID).then(value => {
        setFetchRewards(value);
      });
    
    }
    
  },[globalCustomerID]);


  React.useEffect(()=>{
    setGlobalCustomerID(props.globalCustomerID);
    setGlobalDate(props.globalDate);
  },[props.globalCustomerID, globalCustomerID, props.globalDate, globalDate]);

function parentConfirmCB(value){
  if(value) {
    if(deleteID.RewardID > 0)
    {

      rewardApi.rewardDelete(deleteID).then(res=>{
        if(res.status === 200){
          res.text().then((text)=> {
            if(text === 'Deleted Succefully'){
              setPerformanceMessage({'isOpen':true, 'msgType':'success', 'msg':'Delete Successfully'});
            }
            else{
              setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':'Not Delete Successfully'});
            }

            
          console.log("+++++++++++++++",globalCustomerID)
            if(globalCustomerID > 0)
            {
              fetchDetails(globalCustomerID).then(value => {
                setFetchRewards(value);
              });
            }
            else if(globalCustomerID === 0)
            {
              fetchDetails(globalDate).then(value => {
                setFetchRewards(value);
              });
            }


          }); 
        }
        
      })
    }
  }
  else {
    handleRefreshDelay();
  }
 
}

const handleEditClick = (event, param, clickedButton ) => {
  if(clickedButton === 'Edit')
  {
    // assign the edited row into this variable
    props.setEditRow(param.row);
    props.setTabValue(1);
  }
};

const handleDeleteClick = (event, param, clickedButton ) => {
  if(clickedButton === 'Del')
  {

    setIsopenconfirm({'isOpen':true,'msg':'Delete'});
    const deleteid = {'RewardID': param.id}
    setDeleteID(deleteid);
    props.setTabValue(2);
  }
};


const columns = [
  {
    field: "Edit",
    headerName: 'Edit',
    sortable: false,
    disableColumnMenu:true,
    width: 50,
    renderCell: (cellValues) => (
        <>
          <IconButton 
            color="primary" 
            aria-label="upload picture" 
            variant="contained"
            onClick={(event) => {
                handleEditClick(event, cellValues, 'Edit');
            }}
            component="label"
          >
            <Edit />
          </IconButton>
       </>
      )
  },
  {
    field: "Delete",
    headerName: 'Delete',
    disableColumnMenu:true,
    sortable: false,
    width: 50,
    renderCell: (cellValues) => (
        <>
         <IconButton 
            color="error" 
            aria-label="upload picture" 
            variant="contained"
            onClick={(event) => {
                handleDeleteClick(event, cellValues, 'Del');
            }}
            component="label"
          >
            <Delete />
          </IconButton>
        </>
      )
  },
  { field: 'CustomerID', headerName: 'ID', width: 70, type:'number' },
  { field: 'RewardDate', headerName: 'RewardDate', width: 130,
    renderCell: (params) => {
      const str = params.value;
      const sliceStr = str.substr(0, 10);
      const value = sliceStr ==='0000-00-00' ? '' : dateFormatForDataGridColumn(sliceStr);
      return value;
    },
  },
  { field: 'CustomerName', headerName: 'Customer Name', width: 150, renderCell:(param) => (param.row.CustomerName.toUpperCase()), },
  { field: 'Place', headerName: 'Place', width: 130,  renderCell:(param) => (param.row.Place.toUpperCase()), },
  { field: 'MobileNumber', headerName: 'MobileNumber',width: 130 },
  { field: 'Earn', headerName: 'Earn',width: 100, renderCell:(param) => (param.row.RewardPoints > 0 ? param.row.RewardPoints : ''), },
  { field: 'Redeem', headerName: 'Redeem',width: 100, renderCell:(param) => (param.row.RewardPoints < 0 ? param.row.RewardPoints : ''), },
  { field: 'BillNumber', headerName: 'BillNumber',width: 130 },
  { field: 'Remarks', headerName: 'Remarks' ,width: 130,  renderCell:(param) => (param.row.Remarks.toUpperCase()),},
  { field: 'CreatedOn', headerName: 'CreatedOn',width: 130 ,
  renderCell: (params) => {
    const str = params.value;
    const sliceStr = str.substr(0, 10);
    const value = sliceStr ==='0000-00-00' ? '' : dateFormatForDataGridColumn(sliceStr);
    return value;
  }},
  { field: 'RewardID', headerName: 'RewardID',width: 130 },
  
];

// const selectRow = fetchRewards.length === 0 ? fetchAllCustomer : fetchRewards
  return (
    <>
    <ResponsiveDialog isopenconfirm={isopenconfirm} parentConfirmCB={parentConfirmCB}/>
    <PerformanceMsg performanceMessage={performanceMessage} />
    <div style={{ height: 480, width: '100%' }}>
      <DataGrid rowHeight={25} rows={fetchRewards} columns={columns} pageSize={14} />
    </div>
    </>
  );
}




        // <DataGrid rows={fetchAllCustomer} columns={columns} pageSize={10} 
        //   // rowsPerPageOptions={10}
        //   // <DataGrid rows={fetchAllCustomer} columns={columns} pageSize={10} 
        //   //    componentsProps={{
        //   //     pagination: {
        //   //         sx: {
        //   //         //   color: "white",
        //   //         //   backgroundColor:'#ff4842',
        //   //           fontSize: 30,
        //   //         },
        //   //       }
        //   //     }}
     
        // />

