import * as React from 'react';
import { Typography, Tabs, Tab, Box } from '@mui/material';

// component
import { Rewards, RewardsDataGrid } from '../components/_dashboard/Rewards';
import * as rewardApi from '../api/rewardApi';


export default function CustomerRewards() {
  const [tabValue, setTabValue] = React.useState(1);
  const [editRow, setEditRow] = React.useState(null);
  // customerID and Date is pass form Reward to RewardDataGrid
  const [globalCustomerID, setGlobalCustomerID] = React.useState(0);
  const [globalDate, setGlobalDate] = React.useState(0);
  const [fetchRewards, setFetchRewards]   = React.useState([]);

  React.useEffect(()=>{
    setTabValue(tabValue);
  },[tabValue]);

  React.useEffect(()=>{
    setGlobalCustomerID(globalCustomerID);
  },[globalCustomerID]);

  React.useEffect(()=>{
    setGlobalDate(globalDate);
  },[globalDate]);


  React.useEffect(()=>{
    setEditRow(editRow);
    console.log('editRow',editRow);
  },[editRow]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const childCB = (value) =>
  {
    setTabValue(value);
  }
  React.useEffect(()=>{
      if ((globalDate !== 0) && (globalDate !== '0000-00-00')) {
         console.log({globalDate});
         rewardApi.loadReward(globalDate)
          .then(res=>res.json())
          .then(res=>{
            setFetchRewards(res);
            return res})
          .catch((e)=>(console.log('e',e)))
      }
     
    },[globalDate]);

  return (
    <>
    <Typography variant="h3" gutterBottom sx={{ textAlign:'center'}}>
          Rewards
    </Typography>
    <Box sx={{width: '100%' }}>
    
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value={1} label="Customer Rewards" />
        <Tab value={2} label="View All" />
      </Tabs>
      {(tabValue === 1)? <Rewards tabValue={tabValue} setTabValue={setTabValue} editRow={editRow} parentCB={childCB} setGlobalCustomerID={setGlobalCustomerID} setGlobalDate={setGlobalDate} /> : <RewardsDataGrid tabValue={tabValue} setTabValue={setTabValue} setEditRow={setEditRow}  globalCustomerID={globalCustomerID} fetchRewards={fetchRewards} globalDate={globalDate} />}
      {/* {(tabValue === 1)? <Rewards editRow={editRow} parentCB={childCB} setGlobalCustomerID={setGlobalCustomerID} /> : <RewardsDataGrid tabValue={tabValue} setTabValue={setTabValue} setEditRow={setEditRow}  globalCustomerID={globalCustomerID} />} */}
    </Box>
    
    </>
  );
}
