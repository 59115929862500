import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// import Image from 
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64; // 64 default Value
const APP_BAR_DESKTOP = 92; // 92 default Value

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 2, // 24 default Value
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 2, // 24 default Value
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [displaySideBarOutside, setDisplaySideBarOutside] = useState(false);
  
// sx={{paddingTop:{lg:'0px !important'}}}
  return (
    <RootStyle >
       <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
      {/* <DashboardSidebar onOpenSidebar={() => setOpen(true)}  /> */}
      {/* <DashboardSidebar  isOpenSidebar={open} setDisplaySideBarOutside={setDisplaySideBarOutside} onCloseSidebar={() => setOpen(true)} /> */}
      <MainStyle sx={{paddingTop:{lg:'5px !important'}}}>
        <Outlet   />
       
      </MainStyle>
    </RootStyle>
  );
}
