import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { DataGrid, gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
// import PerformanceMsg from '../../common/PerformanceMsg';
import OnlyPerformanceMsg from '../../common/OnlyPerformanceMsg';
import OnlyResponsiveDialog from '../../common/OnlyResponsiveDialog';
// import ResponsiveDialog from '../../common/OnlyResponsiveDialog';
import { getCustomer, edit, delet, deleteCustomer } from '../../../bo/customerBOSlice';
// util
import { handleRefreshDelay } from '../../../utils/handleRefresh';
import { dateFormatForDataGridColumn } from '../../../utils/formatDate';

// display key and value
const keyValue = (input) => Object.entries(input).forEach(([key,value]) => {
  console.log("test key avalue form object",key,value)
})

export default function CustomerDataGrid(props) {
  const cust = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const [fetchAllCustomer, setFetchAllCustomer]   = useState([]);
  const [deleteID, setDeleteID]  = useState(0);

  // conformation msg
  const [confirmMsg, setConfirmMsg] = useState({'isOpen':false,'msg':''});
  const [confirm, setConfirm] = useState(false);
  // performance msg
  const [performanceMsgOpen, setPerformanceMsgOpen] = useState(false);
  const [performanceMessage, setPerformanceMessage] = useState({'isOpen':false, 'msgType':'warning', 'msg':''});

  useEffect(()=>{
    dispatch(getCustomer(`list`));
  },[]);


  useEffect(()=>{
    if(confirm === true)
    {
      setPerformanceMsgOpen(false);
    }
  },[performanceMsgOpen, confirm]);

  useEffect(()=>{
    console.log("cust",{cust})
    switch(cust.loadingStatus){
      case 'read':
        setFetchAllCustomer(cust.customers);
        break;
      case 'delete':
        setFetchAllCustomer(cust.customers);
        break;
      default:
          break;

    }
    // cust.customers,
  },[ cust]);


function parentConfirmCB(value){
  setConfirm(false);
  setConfirmMsg({'isOpen':false,'msg':''});

  if(value) {
    if(deleteID.CustomerID > 0)
    {
      dispatch(deleteCustomer(deleteID)).then(res=> {
        if(res.payload.status === 500){
          setPerformanceMsgOpen(true);
          setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':'You canot Delete because this customer has Reward Points'});
        }
        else{
          dispatch(getCustomer(`list`)).then(res => {
            if(res.meta.requestStatus === "fulfilled")
            {
              setPerformanceMsgOpen(true);
              setPerformanceMessage({'isOpen':true,'msgType':'success', 'msg':'Delete successfully'});
            }
        })
        }
      })
    }
  }
}

const handleEditClick = (event, param, clickedButton ) => {
  if(clickedButton === 'Edit')
  {
    // assign the edited row into this variable
    dispatch(edit({'selectedCustomer': param.row}));
    props.setTabValue(1);
  }
};

const handleDeleteClick = (event, param, clickedButton ) => {
  if(clickedButton === 'Del')
  {
    setConfirm(true);
    setConfirmMsg({'isOpen':true, 'msg':'Delete'});
    const deleteid = {'CustomerID': param.id}
    setDeleteID(deleteid);
    props.setTabValue(2);
  }
};




const columns = [
  {
    field: "Edit",
    headerName: 'Edit',
    sortable: false,
    disableColumnMenu:true,
    width: 50,
    renderCell: (cellValues) => (
        <>
          <IconButton 
            color="primary" 
            aria-label="upload picture" 
            variant="contained"
            onClick={(event) => {
                handleEditClick(event, cellValues, 'Edit');
            }}
            component="label"
          >
            <Edit />
          </IconButton>
       </>
      )
  },
  {
    field: "Delete",
    headerName: 'Delete',
    disableColumnMenu:true,
    sortable: false,
    width: 50,
    renderCell: (cellValues) => (
        <>
         <IconButton 
            color="error" 
            aria-label="upload picture" 
            variant="contained"
            onClick={(event) => {
                handleDeleteClick(event, cellValues, 'Del');
            }}
            component="label"
          >
            <Delete />
          </IconButton>
        </>
      )
  },
  { field: 'CustomerID', headerName: 'ID', width: 70, type:'number' },
  { field: 'CustomerName', headerName: 'Customer Name', width: 150, renderCell:(param) => (param.row.CustomerName.toUpperCase()), },
  { field: 'RelationStatus', headerName: 'R.Status', renderCell:(param) => (param.row.RelationStatus.toUpperCase()), },
  { field: 'RelationName', headerName: 'Relation Name',renderCell:(param) => (param.row.RelationName.toUpperCase()),},
  { field: 'Address1', headerName: 'Address1', width: 130, renderCell:(param) => (param.row.Address1.toUpperCase()), },
  { field: 'Address2', headerName: 'Address2',  renderCell:(param) => (param.row.Address2.toUpperCase()), },
  { field: 'Place', headerName: 'Place', width: 130,  renderCell:(param) => (param.row.Place.toUpperCase()), },
  { field: 'MobileNumber', headerName: 'MobileNumber' },
  { field: 'Gender', headerName: 'Gender' },
  { field: 'Dob', headerName: 'Dob', width: 130,
    renderCell: (params) => {
      const value = params.value ==='0000-00-00' ? '' : dateFormatForDataGridColumn(params.value);
      return value;
    },
  },
  { field: 'AadharNumber', headerName: 'Aadhar Number', width: 130 },
  { field: 'Email', headerName: 'Email', width: 130 },
  
];


  return (
    <>
    {confirm && <OnlyResponsiveDialog confirmMsg={confirmMsg} parentConfirmCB={parentConfirmCB}/>}
    {performanceMsgOpen && <OnlyPerformanceMsg performanceMessage={performanceMessage} />}
    <div style={{ height: 480, width: '100%' }}>
      <DataGrid rowHeight={25} rows={fetchAllCustomer} columns={columns} pageSize={14} />
    </div>
    </>
  );
}




        // <DataGrid rows={fetchAllCustomer} columns={columns} pageSize={10} 
        //   // rowsPerPageOptions={10}
        //   // <DataGrid rows={fetchAllCustomer} columns={columns} pageSize={10} 
        //   //    componentsProps={{
        //   //     pagination: {
        //   //         sx: {
        //   //         //   color: "white",
        //   //         //   backgroundColor:'#ff4842',
        //   //           fontSize: 30,
        //   //         },
        //   //       }
        //   //     }}
     
        // />

