// ----------------------------------------------------------------------

export default function Datagrid(theme) {
    return {
      MuiDataGrid: {
        styleOverrides: {
        //   root: {
        //     color: 'gold',
        //     },
            footerContainer:{
                // backgroundColor:'error',
                // color:'error',
                justifyContent: 'flex-start !important',
                // justifyContent: 'center !important',
            }
        },
        
      },
    
    };
  }
  