export default function Select(theme) {
    return {
        MuiSelect: {
            styleOverrides: {
                root: {
                    // color:'yellowgreen',
                },
                standard:{
                    '&:focus': {
                        backgroundColor: 'inherit',
                        // backgroundColor: 'gold',
                        // backgroundColor: '#ff9121',
                    }
                }
            }
          }
    };
  }
  