
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material
import { Stack, Card, Grid, Button } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import UploadIcon from '@mui/icons-material/Upload';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import date from 'date-and-time';
import { makeStyles } from '@material-ui/styles';

// components
import Page from '../../Page';


// Util
import { handleRefreshDelay } from '../../../utils/handleRefresh';
import ResponsiveDialog from '../../common/ResponsiveDialog';
import PerformanceMsg from '../../common/PerformanceMsg';
// Api
import { createCustomer , getCustomer, updateCustomer}from '../../../bo/customerBOSlice';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    selectRoot: {
		width: "200px",
		"&:focus": {
			backgroundColor: "yellow"
		}
	},
    MuiSelect: {
        outlined: {
          '&:focus': {
            backgroundColor: '#FFF'
          }
        }
      },
    formControl: {
      width: '100%',
    },
   
  }));



export default function CustomerDetails() {
    // --------------------------------------------------------------------------------------------------  styles   
    const classes = useStyles();
    const customerNameRef = React.useRef(null);
    const [saveUpdateButton, setSaveUpdateButton] = React.useState('Save');
    // conformation msg
    const [isopenconfirm, setIsopenconfirm] = React.useState({'isOpen':false,'msg':''});
    // performance msg
    const [performanceMessage, setPerformanceMessage] = React.useState({'isOpen':false,'msgType':'warning','msg':''});
    const [loading, setLoading] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    
    // Redux Toolkit RKT
    const cust = useSelector((state) => state.customer);
    const dispatch = useDispatch();
    
    const [customerId, setCustomerId] = React.useState(0);
    const [customerName, setCustomerName] = React.useState('');
    const [relationStatus, setRelationStatus] = React.useState('S/O');
    const [relationName, setRelationName] = React.useState('');
    const [address1, setAddress1] = React.useState('');
    const [address2, setAddress2] = React.useState('');
    const [address2List, setAddress2List] = React.useState([]);
    const [place, setPlace] = React.useState('');
    const [placeList, setPlaceList] = React.useState([]);
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [gender, setGender] = React.useState('M');
    const [dd, setDd] = React.useState('00');
    const [mm, setMm] = React.useState('00');
    const [yyyy, setYyyy] = React.useState('0000');
    const [dob, setDob] = React.useState('');
    const [aadharNumber, setAadharNumber] = React.useState('');
    const [email, setEmail] = React.useState('');

    const [custNameErrorInput, setCustNameErrorInput] = React.useState({'customerName':false, 'customerNameLabel':""})
    const [mobileNumberErrorInput, setMobileNumberErrorInput] = React.useState({'mobileNumber':false, 'mobileNumberLabel':''})

    const labelPaddingTop = {paddingTop:'14px'};
    // date variables
    const daylist=['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
    const monthlist=['00','01','02','03','04','05','06','07','08','09','10','11','12'];
    const yearlist=['0000','2001', '2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021','2022'];

    const textUppercase = { textTransform: "uppercase" };

    let errMsg = '';

    function strToDate(dd, mm,yyyy)
    {
        const newDate = `${yyyy}-${mm}-${dd}`;
        return newDate;
    }

    // Customer Details collection in object
    const [customerDetails, setCustomerDetails] = React.useState({
                                                                    'CustomerID':customerId,
                                                                    'CustomerName':customerName,
                                                                    'RelationStatus':relationStatus,
                                                                    'RelationName':relationName,
                                                                    'Address1':address1,
                                                                    'Address2':address2,
                                                                    'Place':place,
                                                                    'MobileNumber':mobileNumber,
                                                                    'Gender':gender,
                                                                    'Dob':strToDate(dd, mm, yyyy),
                                                                    'AadharNumber':aadharNumber,
                                                                    'Email':email,
                                                                });
    
    
    
 // set Loading button is true or false
    
    React.useEffect(()=>{
        dispatch(getCustomer('list'));
    },[]);

    React.useEffect(()=>{
        setCustNameErrorInput(custNameErrorInput);
    },[custNameErrorInput]);

    React.useEffect(()=>{
        setMobileNumberErrorInput(mobileNumberErrorInput);
    },[mobileNumberErrorInput]);

    // it remove duplist string from an array
    function removeDuplicates(arr) {
        return [...new Set(arr)];
    }

    React.useEffect(()=>{
        // Create Place and Address2 list from list api
        if((typeof cust.customers !== 'undefined') && (cust.customers.length > 0))
        {
            const placeLocalList = [];
            const addressLocalList = [];
            cust.customers.forEach(object => {
            if(object.Place !== ''){
                placeLocalList.push(object.Place);
            }
            if(object.Address2 !== ''){
                addressLocalList.push(object.Address2);
            }
            });
            setPlaceList(removeDuplicates(placeLocalList));
            setAddress2List(removeDuplicates(addressLocalList));
        }
    }, [cust.customer]);
    
    React.useEffect(()=>{
        setCustomerName(customerName);
        if(customerName.length === 0)
        {
            setCustNameErrorInput({'customerName':true, 'customerNameLabel':'Enter Atleast 3 letters'});
        }
        else if(customerName.length > 0)
        {
            setCustNameErrorInput({'customerName':false, 'customerNameLabel':null});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    },[customerName]);

    React.useEffect(()=>{
        if(mobileNumber.length === 0)
        {
            setMobileNumberErrorInput({'mobileNumber':true, 'mobileNumberLabel':"Enter 10 digit's number"});
        }
        else if(mobileNumber.length > 0)
        {
            setMobileNumberErrorInput({'mobileNumber':false, 'mobileNumberLabel':null});
        }
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`});
    },[mobileNumber]);


    React.useEffect(()=>{
        setEmail(email);
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':`${errMsg}`})
    },[email]);

    React.useEffect(()=>{
        setIsopenconfirm(isopenconfirm);
    },[isopenconfirm]);

    React.useEffect(()=>{
        setLoading(loading);
    },[loading]);

    React.useEffect(()=>{
        setLoadingDelete(loadingDelete);
    },[loadingDelete]);

    React.useEffect(()=>{
        setSaveUpdateButton(saveUpdateButton);
    },[saveUpdateButton]);

    React.useEffect(()=>{
        setDd(dd);
        setMm(mm);
        setYyyy(yyyy);
        setDob(strToDate(dd, mm, yyyy))
        setPerformanceMessage({'isOpen':false, 'msgType':'error', 'msg':``});
    },[dd, mm, yyyy]);

    React.useEffect(()=>{
        if((cust.loadingStatus === 'edit'))
        {
            setCustomerId(cust.selectedCustomer.CustomerID);
            setCustomerName(cust.selectedCustomer.CustomerName);
            setRelationStatus(cust.selectedCustomer.RelationStatus);
            setRelationName(cust.selectedCustomer.RelationName);
            setAddress1(cust.selectedCustomer.Address1);
            setAddress2(cust.selectedCustomer.Address2);
            setPlace(cust.selectedCustomer.Place);
            setMobileNumber(cust.selectedCustomer.MobileNumber);
            setGender(cust.selectedCustomer.Gender);
            setDob(cust.selectedCustomer.Dob);
            dateToStr(cust.selectedCustomer.Dob);
            setAadharNumber(cust.selectedCustomer.AadharNumber);
            setEmail(cust.selectedCustomer.Email);
            setSaveUpdateButton('Update');
        }
    },[cust.loadingStatus]);

const displayMsg = (cust) => {
    if((cust.loadingStatus === saveUpdateButton.toLowerCase()) && (cust.status === 200))
        {
            setLoading(false);
            // handleRefreshDelay();
            setCustomerId(0);
            setCustomerName('');
            setRelationStatus('S/O');
            setRelationName('');
            setAddress1('');
            setAddress2('');
            setPlace('');
            setMobileNumber('');
            setGender('M');
            setAadharNumber('');
            setEmail('');
            setDd('00');
            setMm('00');
            setYyyy('0000');
        }
}


    React.useEffect(()=>{
        if((cust.loadingStatus === saveUpdateButton.toLowerCase()) && (cust.status === 200))
        {
            setPerformanceMessage({'isOpen':true, 'msgType':'success', 'msg':`${saveUpdateButton} successfully`});
        }else if(cust.status === 500){
            setLoading(false);
            setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`Check Your Internet Connection`});
        }
        setTimeout(() => {
            // your code to be executed after 1 second
            displayMsg(cust);
          }, 1000);
    },[cust.status]);

    const validMail=(value)=>{
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const emailValue = value || null;
        const result = emailValue.match(validRegex);
        let resValue = false;
        if(result === null){
            resValue = false;
        }
        else{
            resValue = true;
        }
       
        return resValue;
    }

    
    const validation = (value) =>{
        
        let resultStatus = false;
        const mobileValue = `${value.MobileNumber}`;
        // console.log({value});
        const validCustName = value.CustomerName.length > 2 ? true : errMsg += '- Fill the Customer Name';
        const validMobileNumber = mobileValue.length === 10 ? true : errMsg += '\n- Mobile Number input field';
        // let validDate = false;
        
        if(validCustName === true && validMobileNumber === true )
        {
            resultStatus = true;
        }else{
            setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`${errMsg}`});
        }
       // validate the email id
        if(email.length > 0 )
        {
            if(validMail(email)===true )
            {

                resultStatus = true;
            }
            else{
                resultStatus = false;
                errMsg += '\n- Enter valid Email Id'
                setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`${errMsg}`});
            }
        }
       
       // validate the date of birth
        if(value.Dob !== '0000-00-00')
        {
           const validDate = date.isValid(`${value.Dob}`, 'YYYY-MM-DD');
            if((validDate === true) && (value.Dob === '0000-00-00')){
                resultStatus = true;
            }else{
                resultStatus = false;
                errMsg += '\n- Enter valid Date of Birth';
                setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`${errMsg}`});
            }

        }
        
        return resultStatus;
    }

    function dateToStr(value)
    {
        const localDate = value.split('-');
        setDd(localDate[2]);
        setMm(localDate[1]);
        setYyyy(localDate[0]);
    }

    const submit = (value) => {
        if(saveUpdateButton === 'Save'){
            dispatch(createCustomer(value));
        }
        else if(saveUpdateButton === 'Update'){
            dispatch(updateCustomer(value));
        }
    }

    function parentConfirmCB(value){
        if(value){
            submit(customerDetails);
        }
        else{
            setLoading(false);
        }
    }

    const handleClick = (e) => {
        const value={
                        'CustomerID':customerId,
                        'CustomerName':customerName,
                        'RelationStatus':relationStatus,
                        'RelationName':relationName,
                        'Address1':address1,
                        'Address2':address2,
                        'Place':place,
                        'MobileNumber':mobileNumber,
                        'Gender':gender,
                        'Dob':strToDate(dd, mm, yyyy)=== '-00-00' ? '0000-00-00' : strToDate(dd, mm, yyyy),
                        'AadharNumber':aadharNumber,
                        'Email':email,
                    }
        setCustomerDetails(value);
        
        if(validation(value))
        {
            setIsopenconfirm({'isOpen':true,'msg':`${saveUpdateButton}`});
            setLoading(true);
            customerNameRef.current.focus();
        }
        else{
            // setButtonValue('');
            setPerformanceMessage({'isOpen':true, 'msgType':'error', 'msg':`${errMsg}`});
            setLoading(false);
        }
    }

    function handleClickDelete() {
        console.log("handleRefreshDelay");
        setLoadingDelete(true);
        // handleRefreshDelay();
        setCustomerId(0);
        setCustomerName('');
        setRelationStatus('S/O');
        setRelationName('');
        setAddress1('');
        setAddress2('');
        setPlace('');
        setMobileNumber('');
        setGender('M');
        setAadharNumber('');
        setEmail('');
        setDd('00');
        setMm('00');
        setYyyy('0000');
        setLoadingDelete(false);
        customerNameRef.current.focus();
    }


    const handleChange = (e, name) =>{
        const result = e.target.value;
        // it allow only number value
        const mobileNumberTest = /^[0-9\b]+$/;
    
        switch(name)
        {
            case 'customerName':
                setCustomerName(result);
                break;
            case 'relationStatus':
                setRelationStatus(result);
                break;
            case 'relationName':
                setRelationName(result);
                break;
            case 'mobileNumber':
                if ((result === '' || mobileNumberTest.test(result)) && (result.length <= 10)) {
                    setMobileNumber(result);
                 }
                 else if( (!mobileNumberTest.test(result)) && (result.length <= 10))
                 {
                    setMobileNumber('');
                 }
                break;
            case 'address1':
                setAddress1(result);
                break;
            case 'address2':
                setAddress2(result);
                break;
            case 'place':
                setPlace(result);
                break;
            case 'gender':
                setGender(result);
                break;
            case 'aadharNumber':
                if((Number(result) >=0 ) && (result.length <=12))
                {
                    setAadharNumber(result);
                }
                break;
            case 'email':
                setEmail(result);
                break;
            case 'dd':
                if(Number(result)<=31)
                {
                    setDd(result);
                }else{
                    setDd('');
                }
                break;
            case 'mm':
                if(Number(result)<=12)
                {
                    setMm(result);
                }else{
                    setMm('');
                }
                break;
            case 'yyyy':
                if(Number(result)>=1970 && Number(result)<=9999 )
                {
                    setYyyy(result);
                }else{
                    setYyyy('');
                }
                break;
            default:
        }
    }

    const handleKeyPressEnter = (e) =>{
        if(e.which === 13)
        {
            handleClick();
        }
    }

   
  return (
    <Page title="Master : Customer Entry | Meenakshi Solution">
        <ResponsiveDialog isopenconfirm={isopenconfirm} parentConfirmCB={parentConfirmCB}/>
        <PerformanceMsg performanceMessage={performanceMessage} setPerformanceMessage={performanceMessage} />
        <Card>
            <div style={{padding:'10px 20px'}}>
                   <Grid container spacing={2} py={4} >
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Grid container spacing={2} >
                                {/* 1 row */}
                               
                                 {/* 2 row */}
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={labelPaddingTop} >Customer Name*</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        required
                                        id="customerName"
                                        inputRef={customerNameRef}
                                        value={customerName}
                                        onChange={(e)=>{handleChange(e,"customerName")}}
                                        color="primary"
                                        variant="outlined"
                                        label={custNameErrorInput.customerNameLabel}
                                        error={custNameErrorInput.customerName}
                                        fullWidth
                                        inputProps={{ style: textUppercase }}
                                        autoFocus
                                    />
                                </Grid>

                                {/* 2 row */}
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={labelPaddingTop} >Mobile Number*</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        required
                                        id="filled-required"
                                        value={mobileNumber}
                                        onChange={(e)=>{handleChange(e,"mobileNumber")}}
                                        onKeyPress={handleKeyPressEnter}
                                        inputProps={{ style: textUppercase }}
                                        label={mobileNumberErrorInput.mobileNumberLabel}
                                        error={mobileNumberErrorInput.mobileNumber}
                                        color='primary'
                                        variant="outlined"
                                        // pattern="[0-9]*"
                                        // inputMode="numeric"
                                        fullWidth
                                    />
                                </Grid>

                                {/* 3 row */}

                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={labelPaddingTop} >Relation Name</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Grid container>
                                        <Grid item xs={3} sm={3} md={3} lg={3} >
                                        <FormControl variant="standard" sx={{    paddingTop: '8px'}}>
                                            <InputLabel id="demo-simple-select-label" color='primary'>Status</InputLabel>
                                            <Select
                                                // classes={{ root: classesNew.selectRoot }}
                                                // classes={style:{ root: classesNew.selectRoot }}
                                                // sx={{backgroundColor:'pink'}}
                                                classes={{ root: classes.selectRoot }}
                                                labelId="demo-simple-select-label"
                                                id="relationStatus"
                                                value={relationStatus}
                                                color='primary'
                                                onChange={(e)=>{handleChange(e,"relationStatus")}}
                                            >
                                                <MenuItem value='S/O'>S/O</MenuItem>
                                                <MenuItem value='D/O'>D/O</MenuItem>
                                                <MenuItem value='F/O'>W/O</MenuItem>
                                                <MenuItem value='C/O'>C/O</MenuItem>
                                               
                                            </Select>
                                             
                                            </FormControl>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} lg={9} >
                                            <TextField
                                                required
                                                id="relationName"
                                                value={relationName}
                                                onChange={(e)=>{handleChange(e,"relationName")}}
                                                color='primary'
                                                variant="outlined"
                                                inputProps={{ style: textUppercase }}
                                                fullWidth
                                            />
                                            </Grid>
                                            </Grid> 
                                </Grid>
                                
                               
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={labelPaddingTop} >Aadhar Number</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        id="filled-required"
                                        value={aadharNumber}
                                        onChange={(e)=>{handleChange(e,"aadharNumber")}}
                                        inputProps={{ style: textUppercase }}
                                        color='primary'
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                    {/* 4 row */}
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={labelPaddingTop} >Address 1</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        id="filled-required"
                                        value={address1}
                                        onChange={(e)=>{handleChange(e,"address1")}}
                                        inputProps={{ style: textUppercase }}
                                        color='primary'
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                 <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={labelPaddingTop} >Address 2</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={address2} 
                                        options={address2List.map((option) => option)}
                                        
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} 
                                                                    // inputProps={{ style: textUppercase }}
                                                                    variant="outlined" fullWidth 
                                                                    onChange={(e)=>{handleChange(e, "address2")}}
                                                                    onBlur={(e)=>{handleChange(e, "address2")}}
                                                                />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} />
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} />
                                
                                <Grid item xs={12} sm={12} md={2} lg={2} >
                                    <h4 style={labelPaddingTop} >Place</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} >
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={place} 
                                        options={placeList.map((option) => option)}
                                        // inputProps={{ style: textUppercase }}
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} 
                                        // inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    // inputProps={{ style: textUppercase }}
                                                                    variant="outlined" fullWidth 
                                                                    onChange={(e)=>{handleChange(e, "place")}}
                                                                    onBlur={(e)=>{handleChange(e, "place")}}
                                                                />}
                                    />
                                   
                                </Grid>
                                 {/* 5 row */}
                                 <Grid item xs={12} sm={12} md={2} lg={2} >
                                    <h4 style={labelPaddingTop} >Gender</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} >
                                    <FormControl variant="outlined" sx={{ width:'150px'}}>
                                        <Select
                                            labelId="genderId"
                                            id="gender"
                                            value={gender}
                                            color='primary'
                                            onChange={(e)=>{handleChange(e,"gender")}}
                                        >
                                            <MenuItem value='M'>Male</MenuItem>
                                            <MenuItem value='F'>Female</MenuItem>
                                            <MenuItem value='Others'>Others</MenuItem>
                                        </Select>
                                            
                                    </FormControl>
                                </Grid>
                               
                                                                {/* 6 row */}
                                {/* <div style={{backgroundColor:'red'}}>  */}
                                <Grid item xs={12} sm={12} md={2} lg={2} >
                                    <h4 style={labelPaddingTop} >Email ID</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} >
                                    <TextField
                                        id="filled-required"
                                        type='email'
                                        value={email}
                                        onChange={(e)=>{handleChange(e,"email")}}
                                        inputProps={{type:"email", required: true }}
                                        color='primary'
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} p={2} >
                                    <h4 style={labelPaddingTop} >Birth Date</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} >
                                        <Grid container>
                                            <Grid item xs={3} sm={3} md={3} lg={4} >
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    value={dd || '00'}
                                                    options={daylist.map((option) => option)}
                                                    disableClearable
                                                    renderInput={(params) => 
                                                                    <TextField {...params} label="dd" variant="outlined"  
                                                                    type='number'
                                                                    onChange={(e)=>{handleChange(e,"dd")}}
                                                                    onBlur={(e)=>{handleChange(e,"dd")}}
                                                                    />
                                                                }
                                                />
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={4}>
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    value={mm || '00'}
                                                    options={monthlist.map((option) => option)}
                                                    disableClearable
                                                    renderInput={(params) => <TextField {...params} label="mm" type='number' variant="outlined"
                                                    onChange={(e)=>{handleChange(e,"mm")}}
                                                    onBlur={(e)=>{handleChange(e,"mm")}}
                                                    />}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={4} >
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    freeSolo
                                                    value={yyyy || '0000'} 
                                                    options={yearlist.map((option) => option)}
                                                    disableClearable
                                                    renderInput={(params) => <TextField {...params} label="yyyy" type='number' variant="outlined" fullWidth 
                                                    onChange={(e)=>{handleChange(e,"yyyy")}}
                                                    onBlur={(e)=>{handleChange(e,"yyyy")}}
                                                    />}
                                                />
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                            </Grid>
                        </Grid>
                        {/* photo */}
                        <Grid item xs={12} sm={12} md={3} lg={3}>

                            <Stack direction="row">
                                <Grid item xs={3} sm={6} md={1} lg={1} />
                                <Grid item xs={12} sm={12} md={10} lg={10} >
                                        <Skeleton variant="rounded"  height={250} sx={{width:'100%',float: 'right'}} />
                                </Grid>
                                <Grid item xs={3} sm={6} md={1} lg={1} />
                            </Stack>
                            <Stack direction="row" >
                                    <Grid item xs={2} sm={4} md={2} lg={2}  />
                                    <Grid item xs={2} sm={1} md={2} lg={2}  >
                                        <Button >
                                            <AddAPhotoIcon variant="rounded" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={2} lg={2}  >
                                        <Button>
                                            <PhotoSizeSelectLargeIcon variant="rounded" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={2} lg={2}  >
                                        <Button>
                                            <UploadIcon variant="rounded" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={2} lg={2} >
                                        <Button>
                                            <DeleteIcon variant="rounded" />
                                        </Button>
                                    </Grid>
                                    {/* <Grid item xs={6} sm={9} md={6} lg={6}  /> */}
                            </Stack>
                            <br />
                            <br />
                            <Stack direction="row" >
                            <Grid item xs={12} sm={12} md={12} lg={12} py={1} >
                                <LoadingButton
                                    size="large"
                                    color="primary"
                                    onClick={handleClick}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    fullWidth
                                    >
                                    {saveUpdateButton}
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} py={1}  >
                                <LoadingButton
                                    size="large"
                                    color="error"
                                    onClick={handleClickDelete}
                                    loading={loadingDelete}
                                    loadingPosition="start"
                                    startIcon={<DeleteIcon />}
                                    variant="contained"
                                    fullWidth
                                    >
                                    Cancel
                                </LoadingButton>
                            </Grid>
                            </Stack>


                        </Grid>
                        {/* photo end */}
                        <Grid item xs={12} sm={12} md={12} lg={12} />
                       
                        <Grid item xs={12} sm={12} md={12} lg={12} />
                   </Grid>
                       
                         
            </div>
        </Card>
    </Page>
  );
}
