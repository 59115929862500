import React, { useState, forwardRef, useEffect } from 'react';
// import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import { makeStyles } from '@material-ui/styles';


// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function PerformanceMsg(props) {
    const classes = useStyles();
    const [isopen ,setIsopen] = useState(true)
    const [performanceMessage, setPerformanceMessage] = React.useState({'isOpen':false,'msgType':'warning','msg':''});


    useEffect(()=>{
        // console.log('ssss',props.performanceMessage)
        const { isOpen, msgType, msg } = props.performanceMessage;
        setPerformanceMessage({
            'isOpen' : isOpen,
            'msgType' : msgType,
            'msg' : msg,
        })
        setIsopen(isOpen);
    },[props.performanceMessage]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsopen(false);
    };

    function Message()
    {
        return (
            <div className={classes.root}>
       
            <Snackbar open={isopen} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <MuiAlert elevation={6} variant="filled" severity={performanceMessage.msgType} >
                    <div>
                        {performanceMessage.msg.split("\n").map((i,key) => (<div key={key}>{i}</div>))}
                    </div>
                </MuiAlert>
            </Snackbar>
       
        </div>
        )
    }

    return (
        <>
        { isopen ? <Message /> : null }
        </>
    );
}
